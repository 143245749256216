
import React,{Fragment, Component} from 'react';
export default class Chat extends Component {
  
  render() {
    return(
      <Fragment>
        <h1>404 This is not where you wanted to be... Fly away my friend!</h1>
      </Fragment>
      
    )
  }
}